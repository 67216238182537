<template>
  <div>
    <div
      v-for="game in games"
      :key="game.ID"
      class="game-score"
      >
      <div v-if="idteam1 === game.Team1_id && idteam2 === game.Team2_id">
        <span v-if="game.ScoreTeam1 > game.ScoreTeam2" class="score-block">
          <span :class="dataSport.textClass">{{game.ScoreTeam1}}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{game.ScoreTeam2}}</span>
        </span>
        <span v-else-if="game.ScoreTeam1 < game.ScoreTeam2" class="score-block">
          <span class="text-white">{{game.ScoreTeam1}}</span>
          <span class="separator text-white">-</span>
          <span :class="dataSport.textClass">{{game.ScoreTeam2}}</span>
        </span>
        <span v-else-if="game.ScoreTeam1 === game.ScoreTeam2 && game.State === 'finished'" class="score-block">
          <span class="text-white">{{game.ScoreTeam1}}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{game.ScoreTeam2}}</span>
        </span>
        <span v-else>
          <b class="separator text-white-50">-</b>
        </span>
      </div>
      <div v-else-if="idteam1 === game.Team2_id && idteam2 === game.Team1_id">
        <span v-if="game.ScoreTeam2 > game.ScoreTeam1" class="score-block">
          <span :class="dataSport.textClass">{{game.ScoreTeam2}}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{game.ScoreTeam1}}</span>
        </span>
        <span v-else-if="game.ScoreTeam2 < game.ScoreTeam1" class="score-block">
          <span class="text-white">{{game.ScoreTeam2}}</span>
          <span class="separator text-white">-</span>
          <span :class="dataSport.textClass">{{game.ScoreTeam1}}</span>
        </span>
        <span v-else-if="game.ScoreTeam2 === game.ScoreTeam1 && game.State === 'finished'" class="score-block">
          <span class="text-white">{{game.ScoreTeam1}}</span>
          <span class="separator text-white">-</span>
          <span class="text-white">{{game.ScoreTeam2}}</span>
        </span>
        <span v-else>
          <b class="separator text-white-50">-</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridGamesScore",
  props: ["games", "idteam1", "idteam2", "dataSport"]
}
</script>

<style scoped>
.separator {
  font-size: 17px;
  font-weight: 400;
  padding: 0 2px;
}
.game-score{
  font-size: 14px;
  line-height: 17px;
}
.score-block::after {
  content: ' ';
  display: block;
}
</style>